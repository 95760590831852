import React from 'react';
import ReactDOM from 'react-dom/client';  // Используем React 18
import { HashRouter } from 'react-router-dom';  // Импортируем HashRouter
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    <App />
  </HashRouter>
);
