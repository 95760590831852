import React, { useEffect, useState } from 'react';
import Balance from './components/Balance';
import ActionButtons from './components/ActionButtons';
import ExtraButtons from './components/ExtraButtons';
import InvoicesButton from './components/InvoicesButton';
import InvoicesList from './components/InvoicesList';  // Добавим InvoicesList
import { Route, Routes } from 'react-router-dom';  // Only import Route and Routes
import PaymentConfirmation from './components/PaymentConfirmation';
import './App.css';

function App() {
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState({ tenge: 0, rub: 0 });
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [error, setError] = useState('');

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.expand();
    if (tg.initDataUnsafe && tg.initDataUnsafe.user) {
      const telegramId = tg.initDataUnsafe.user.id;
      setUser(tg.initDataUnsafe.user);
      fetchBalance(telegramId);
      fetchInvoiceCount(telegramId);  // Fetch invoice count
    } else {
      setError('WebApp API недоступен.');
    }
  }, []);

  const fetchBalance = (telegramId) => {
    fetch(`https://back.tengetestbot.ru/api/getBalance/${telegramId}`)
      .then(response => response.json())
      .then(data => setBalance({ tenge: data.balance_tenge, rub: data.balance_rub }))
      .catch(error => setError('Ошибка при получении баланса: ' + error.message));
  };

  const fetchInvoiceCount = (telegramId) => {
    fetch(`https://back.tengetestbot.ru/api/get-invoice-count/${telegramId}`)
      .then(response => response.json())
      .then(data => setInvoiceCount(data.invoice_count))
      .catch(error => setError('Ошибка при получении количества счетов: ' + error.message));
  };

  return (
    <div className="container">
      <Routes>
        {/* Default route showing the balance page */}
        <Route path="/" element={
          <>
            <div className="header">{user ? `Привет, ${user.first_name}!` : 'Привет!'}</div>
            <Balance balance={balance} />
            <ActionButtons />
            <ExtraButtons />
            <InvoicesButton invoiceCount={invoiceCount} />
            {error && <div className="error">{error}</div>}
          </>
        } />

        {/* Route for payment confirmation */}
        <Route path="/payment/:paymentToken" element={<PaymentConfirmation />} />

        {/* Route for invoices list */}
        <Route path="/invoices" element={<InvoicesList telegramId={user?.id} />} />
      </Routes>
    </div>
  );
}

export default App;
