import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './InvoicesList.css';

function InvoicesList({ telegramId }) {
  const [invoices, setInvoices] = useState([]);
  const [error, setError] = useState(''); // Состояние для ошибки

  useEffect(() => {
    // Начинаем fetch запрос
    fetch(`https://back.tengetestbot.ru/api/get-invoices?telegram_id=${telegramId}`)
      .then(response => {
        if (!response.ok) {
          // Если статус не 200, выбрасываем ошибку
          return response.text().then((text) => {
            throw new Error(`Ошибка сервера: ${text}`);
          });
        }
        return response.json(); // Парсим JSON-ответ
      })
      .then(data => {
        if (data.invoices) {
          setInvoices(data.invoices); // Устанавливаем счета
        } else {
          throw new Error('Некорректный ответ от сервера');
        }
      })
      .catch(error => {
        console.error('Ошибка при получении счетов:', error);
        setError(`Ошибка при получении счетов: ${error.message}`);
      });
  }, [telegramId]);

  return (
    <div className="invoice-list">
      <h2>Список счетов</h2>
      {error ? (
        <div className="error-message">{error}</div> // Выводим ошибку как HTML элемент
      ) : (
        <ul>
          {invoices.map((invoice, index) => (
            <li key={index}>
              <Link to={`/payment/${invoice.payment_token}`}>
                <span>{invoice.amount} {invoice.currency}</span> 
                <span>Статус: {invoice.status}</span>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default InvoicesList;
