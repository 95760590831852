import React from 'react';

function ActionButtons() {
  return (
    <div className="button-container">
      <button className="action-btn" onClick={() => alert("Функция отправки скоро будет доступна.")}>Отправить</button>
      <button className="action-btn" onClick={() => alert("Функция пополнения скоро будет доступна.")}>Пополнить</button>
      <button className="action-btn" onClick={() => alert("Функция обмена скоро будет доступна.")}>Обменять</button>
    </div>
  );
}

export default ActionButtons;
