import React from 'react';
import { Link } from 'react-router-dom'; // Добавляем Link для перехода
import './InvoicesButton.css'; // Подключим стили

const InvoicesButton = ({ invoiceCount }) => {
  return (
    <div className="invoices-button-container">
      <Link to="/invoices">
        <button className="invoices-button">
          <span>Посмотреть счета</span>
          {invoiceCount > 0 && (
            <span className="invoice-count-badge">{invoiceCount}</span>
          )}
        </button>
      </Link>
    </div>
  );
};

export default InvoicesButton;
