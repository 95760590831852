import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const PaymentConfirmation = () => {
  const { paymentToken } = useParams();  // Extract paymentToken from URL
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationStatus, setConfirmationStatus] = useState(null);

  useEffect(() => {
    // Fetch payment details using the paymentToken
    const fetchPaymentDetails = async () => {
      try {
        const response = await fetch(`https://back.tengetestbot.ru/api/payment/details/${paymentToken}`);
        if (!response.ok) {
          throw new Error('Error fetching payment details');
        }
        const data = await response.json();
        setPaymentDetails(data);
      } catch (error) {
        setErrorMessage(error.message);
      }
    };

    fetchPaymentDetails();
  }, [paymentToken]);

  const confirmPayment = async () => {
    const telegramId = window.Telegram.WebApp.initDataUnsafe?.user?.id;

    if (!telegramId) {
      setErrorMessage('Telegram WebApp is not available');
      return;
    }

    try {
      const response = await fetch(`https://back.tengetestbot.ru/api/payment/confirm/${paymentToken}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ telegram_id: telegramId }),
      });

      if (!response.ok) {
        throw new Error('Error confirming payment');
      }

      setConfirmationStatus('Payment confirmed successfully');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="payment-confirmation">
      {errorMessage && <p className="error">{errorMessage}</p>}
      {confirmationStatus ? (
        <h3>{confirmationStatus}</h3>
      ) : (
        paymentDetails && (
          <div>
            <h3>Подтвердите оплату</h3>
            <p>Сумма: {paymentDetails.amount} {paymentDetails.currency}</p>
            <button onClick={confirmPayment}>Подтвердить оплату</button>
          </div>
        )
      )}
    </div>
  );
};

export default PaymentConfirmation;
