import React from 'react';

const ExtraButtons = () => {
  let tg;
  
  if (window.Telegram && window.Telegram.WebApp) {
    tg = window.Telegram.WebApp;
  }

  return (
    <div className="extra-buttons">
      <button 
        className="extra-btn" 
        onClick={() => window.open('http://t.me/payKZTbot/app', '_blank')}
      >
        Что это?
      </button>
      <button 
        className="extra-btn" 
        onClick={() => tg ? tg.openLink('https://services.verigram.cloud/hf2/01J4JXFRF4MWW0M04VFQH9BR0T-F/') : alert('Telegram WebApp is not available')}
      >
        Пройти Верификацию
      </button>
    </div>
  );
};

export default ExtraButtons;
