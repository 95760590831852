import React from 'react';

function Balance({ balance }) {
  return (
    <div className="balance-container">
      <div className="balance-header">Ваш баланс</div>
      <div className="balance-value">₸ {balance.tenge}</div>
      <div className="balance-value">₽ {balance.rub}</div>
    </div>
  );
}

export default Balance;
